
import { Vue, prop } from "vue-class-component";

class Props {
  name = prop<string>({
    default: "option",
    type: String
  });
  options = prop<Array<string>>({
    default: [],
    type: Array
  });
  labels = prop<Array<string>>({
    default: [],
    type: Array
  });
  modelValue = prop<string>({
    default: ""
  });
  customClass = prop<string>({
    default: "",
    type: String
  });
  labelClass = prop<string>({
    default: "",
    type: String
  });
}
export default class LpRadion extends Vue.with(Props) {
  onChange(value: string) {
    this.$emit("update:modelValue", value);
    this.$emit("onClick");
  }
}
