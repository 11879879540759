/* eslint-disable @typescript-eslint/camelcase */
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { RequestCreateClaim } from "@/domain/entities/Claim";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import { ClaimRequestInterface } from "../contracts/ClaimRequest";
import toFormDataUtils from "@/app/infrastructures/misc/common-library/ToFormDataUtils";
export class ClaimListRequest {
  page = 1;
  limit = 20;
  search = "";
  category = "";
  claimStatus = "";
  isTotalData = true;

  constructor(fields?: Partial<ClaimListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
export class CreateClaimRequest implements ClaimRequestInterface {
  payload: RequestCreateClaim = new RequestCreateClaim();
  constructor(fields?: Partial<RequestCreateClaim>) {
    Object.assign(this.payload, fields);
  }

  toFormData(): FormData {
    const { ...payload } = this.payload;
    return toFormDataUtils(ConvertObjectCamelToSnakeCase(payload));
  }
}
