
import { Options, prop, Vue } from "vue-class-component";

class Props {
  modelValue = prop<boolean>({
    default: false,
    type: Boolean
  });
  title = prop<string>({
    default: "",
    type: String
  });
  isUsingReset = prop<boolean>({
    default: false,
    type: Boolean
  });
  isReset = prop<boolean>({
    default: false,
    type: Boolean
  });
  maxSheetHeight = prop<number>({
    default: 100,
    type: Number
  });
}

@Options({
  emits: ["onFilterReset"],
  watch: {
    modelValue(open) {
      this.checkIsSheetOpen(open);
      this.refs.body.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }
})
export default class BottomSheet extends Vue.with(Props) {
  sheetHeight = 0;
  dragPosition = null as any;
  fitContentSheetHeight = 0;
  resizeObserver = null as any;
  refs = this.$refs as any;

  mounted() {
    window.addEventListener("mousemove", this.onDragMove);
    window.addEventListener("touchmove", this.onDragMove);

    window.addEventListener("mouseup", this.onDragEnd);
    window.addEventListener("touchend", this.onDragEnd);

    const refs = this.$refs as any;
    this.refs = this.$refs;

    if (refs.body.children.length) {
      this.resizeObserver = new (window as any).ResizeObserver(() => {
        this.fitContentSheetHeight = this.getFitContenSheettHeight();
        this.setSheetHeight(this.fitContentSheetHeight);
      });

      this.resizeObserver.observe(refs.body.children[0]);
    }

  }
  beforeDestroy() {
    if (this.refs.body.children.length) {
      this.resizeObserver.unobserve(this.refs.body.children[0]);
    }
  }

  resetFilter() {
    if (this.isReset) {
      this.$emit("onFilterReset");
    }
  }

  checkIsSheetOpen(open: boolean) {
    if (this.fitContentSheetHeight > 90) {
      this.setSheetHeight(100);
    } else {
      this.setSheetHeight(this.fitContentSheetHeight);
    }

    if (open) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "auto";
    }
  }

  setSheetHeight(value: number) {
    const refs = this.$refs as any;
    if (!refs.sheetContents) return;
    this.sheetHeight = Math.max(0, Math.min(100, value));

    let sheetHeight = this.sheetHeight;

    if (this.maxSheetHeight !== 100) {
      sheetHeight = this.maxSheetHeight;
    }

    refs.sheetContents.style.height = `${sheetHeight}vh`;
  }

  isFocused(element: any) {
    return document.activeElement === element;
  }

  touchPosition(event: any) {
    return event.touches ? event.touches[0] : event;
  }

  onDragStart(event: any) {
    this.dragPosition = this.touchPosition(event).pageY;
    const refs = this.$refs as any;
    refs.sheetContents.classList.add("not-selectable");
    refs.draggableArea.style.cursor = document.body.style.cursor = "grabbing";
  }

  onDragMove(event: any) {
    if (this.dragPosition === undefined) return;

    const y = this.touchPosition(event).pageY;
    const deltaY = this.dragPosition - y;
    const deltaHeight = (deltaY / window.innerHeight) * 100;

    this.setSheetHeight(this.sheetHeight + deltaHeight);
    this.dragPosition = y;
  }

  onDragEnd() {
    const refs = this.$refs as any;
    this.dragPosition = undefined;
    if (refs.sheetContents) {
      refs.sheetContents.classList.remove("not-selectable");
    }

    if (refs.draggableArea) {
      refs.draggableArea.style.cursor = document.body.style.cursor = "";
    }

    if (
      this.sheetHeight < this.fitContentSheetHeight ||
      this.sheetHeight > this.fitContentSheetHeight
    ) {
      if (this.fitContentSheetHeight === 100) {
        this.setSheetHeight(100);
      } else if (this.sheetHeight < 25) {
        this.$emit("input", false);
      } else {
        this.setSheetHeight(this.fitContentSheetHeight);
      }
    }
  }

  getFitContenSheettHeight() {
    const refs = this.$refs as any;
    const headerHeight = refs.titleHeader?.clientHeight + 70;

    return Math.min(
      ((refs.body?.children[0].scrollHeight + headerHeight) /
        window.innerHeight) *
        100
    );
  }
}
