import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { ClaimEntities } from "@/domain/entities/Claim";
import { ClaimListRequest } from "@/data/payload/api/ClaimRequest";
import { ClaimPresenter } from "../presenters/ClaimPresenter";
import { CreateClaimRequest } from "@/data/payload/api/ClaimRequest";

export interface ClaimState {
  claimData: ClaimEntities;
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  searchValue: string;
  status: string;
  category: string;
  firstRequest: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "claim-monitoring" })
class ClaimStore extends VuexModule implements ClaimState {
  public claimData = new ClaimEntities(new Pagination(1, 20), []);
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public searchValue = "";
  public status = "";
  public category = "";
  public firstRequest = true;

  @Action
  public GetDetailClaim(params: { sttNo: string }) {
    const presenter = container.resolve(ClaimPresenter);
    return presenter.getDetailClaim(params.sttNo);
  }

  @Action
  public createClaim(params: CreateClaimRequest) {
    const presenter = container.resolve(ClaimPresenter);
    return presenter.createClaim(params);
  }

  // main fetching
  @Action
  public async getDataClaimList(params: {
    path: string;
    filter: ClaimListRequest;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(ClaimPresenter);
    return presenter
      .getDataClaimList(params.path, params.filter)
      .then((res: ClaimEntities) => {
        this.setClaimData(res);
        this.setErrorCause("");
        this.setError(false);
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setClaimData(data: ClaimEntities) {
    this.claimData = data;
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  @Mutation
  public setError(status: boolean) {
    this.isError = status;
  }

  @Mutation
  public setErrorCause(cause: string) {
    this.errorCause = cause;
  }

  // template fetching
  @Action
  public fetchDataClaimList() {
    this.getDataClaimList({
      path: "claim-status",
      filter: new ClaimListRequest({
        page: this.claimData.pagination.page,
        limit: this.claimData.pagination.limit,
        search: this.searchValue,
        category: this.category,
        claimStatus: this.status
      })
    });
  }

  @Action
  public initDataClaimList(defaultObject?: any) {
    const defaultStatus = defaultObject?.status || "";
    this.setStatus(defaultStatus);
    this.setFirstPage();
    this.setFirstRequest(true);
    this.fetchDataClaimList();
  }

  @Action
  public loadDataClaimList() {
    this.setFirstRequest(false);
    this.fetchDataClaimList();
  }

  @Mutation
  public setFirstPage() {
    this.claimData.pagination.page = 1;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Action
  public onSearchValue(value: string) {
    this.setFirstPage();
    this.setSearch(value);
    this.loadDataClaimList();
  }

  @Mutation
  public setSearch(value: string) {
    this.searchValue = value;
  }

  @Action
  public onClearSearch() {
    this.setSearch("");
    this.fetchDataClaimList();
  }

  @Action
  public onSelectStatus(value: string) {
    this.setFirstPage();
    this.setStatus(value);
    this.loadDataClaimList();
  }

  @Mutation
  public setStatus(status: string) {
    this.status = status;
  }

  @Action
  public onSelectCategory(value: string) {
    this.setFirstPage();
    this.setCategory(value);
    this.loadDataClaimList();
  }

  @Action
  public onSelectFilterClaim() {
    this.setFirstPage()
    this.loadDataClaimList()
  }

  @Mutation
  public setCategory(category: string) {
    this.category = category;
  }
}

export const ClaimController = getModule(ClaimStore);
